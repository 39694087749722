export default (state = {}, action) => {
	switch (action.type) {
        case "LOGIN_USER_V2": {

            const newState = Object.assign({}, state);

            newState.EmailAlreadyExistMessage = "";
            newState.EmailNotExistMessage = "";
            newState.PasswordNotMatchMessage = "";

            if (action.payload && action.payload.error && action.payload.error != "") {
                var errorMessage = "";

                if (typeof action.payload.error === "string") {
                    errorMessage = action.payload.error.replace("ValidationError: ", "");
                }

                if (action.payload.type == "register") {
                    newState.invalidSignIn = true;
                    newState.EmailAlreadyExistMessage = errorMessage;
                }
                else if (action.payload.type == "login" && action.payload.error === "This email address is not registered") {
                    newState.EmailNotExistMessage = action.payload.error;
                }
                else if (action.payload.type == "login" && action.payload.error === "Does not match the registered password") {
                    newState.PasswordNotMatchMessage = action.payload.error;
                }
                else {
                    newState.invalidSignIn = true;
                    newState.invalidSignInMessage = action.payload.error;
                }

            }
            else {
                console.log('r', action.payload);
                if (typeof window !== 'undefined') {
                    window.localStorage.setItem("loggedInUser", JSON.stringify(action.payload));
                }

                newState.userinfo = action.payload;

                if (typeof newState.userinfo === 'string') {
                    newState.userinfo = JSON.parse(newState.userinfo);
                }

                if (action.cb) {
                    action.cb();
                }

                newState.invalidSignIn = false;
                newState.invalidSignInMessage = "";
            }
            return newState;
        }

        case "RESET_ERROR_MESSAGES": {
            const newState = Object.assign({}, state);

            newState.EmailAlreadyExistMessage = "";
            newState.EmailNotExistMessage = "";
            newState.PasswordNotMatchMessage = "";
        
            return newState;
        }

		default: return { ...state };
	}
}